<template>
	<WwModal
		:modal-id="DELETE_FROM_CART_MODAL"
		modal-border-radius="rounded-t-2xl md:rounded-3xl"
		modal-margin="m-0"
		modal-padding="p-2 md:p-3"
		modal-position="items-end md:items-center"
		max-width="w-full md:max-w-lg"
		:transition-direction="!isMobile ? 'down' : 'bottom'"
	>
		<template #close="{ handleHideButtonClick }">
			<div class="absolute top-4 right-3 md:right-6">
				<button
					type="button"
					class="flex items-center justify-center w-8 h-8 text-black bg-white rounded-full hover:text-gray-500 focus:outline-none focus:ring-2 focus:ring-green-300"
					@click="handleHideButtonClick"
				>
					<span class="sr-only">
						Close panel
					</span>
					<CloseIcon />
				</button>
			</div>
		</template>
		<template #header="{ handleHideButtonClick }">
			<div
				:class="[
					isExpanded ? 'scale-x-100' : 'scale-x-0',
					'md:hidden absolute transition-all duration-500 transform left-1/2 -translate-x-1/2 -translate-y-1/2 top-1.5 h-1.5 rounded-full w-20 shadow-inner bg-green-500'
				]"
				@click="handleHideButtonClick"
			/>
		</template>
		<template #body>
			<div class="grid content-center justify-center grid-cols-1 gap-6 pt-4 pb-8 overflow-y-hidden text-center xs:px-6 sm:px-10">
				<div class="text-2xl font-bold text-black">
					Are you sure you want to remove this item?
				</div>

				<div class="block pb-2 text-3xl font-bold text-center">
					{{ stagedItemToRemove }}
				</div>
			</div>
		</template>

		<template #footer="{ handleHideButtonClick }">
			<div class="grid grid-cols-1 gap-4 pb-1 uppercase xs:text-sm">
				<WwSubmitButton
					class="mx-auto"
					:disabled="false"
					:is-loading="isLoading"
					button-text="DELETE PRODUCT"
					button-height="h-11 md:h-13"
					button-width="w-48"
					border-radius="rounded-md"
					@submit="handleDeleteButtonClick"
				/>
				<button
					class="px-4 py-1 mx-auto font-bold text-center text-green-500 transition-all duration-100 rounded-md hover:text-purple-500 ease xl:hover:bg-gray-200"
					@click="handleHideButtonClick"
				>
					CANCEL
				</button>
			</div>
		</template>
	</WwModal>
</template>

<script async>
import { mapActions, mapGetters, mapMutations } from 'vuex'

import CloseIcon from '@/components/icons/CloseIcon.vue'
import WwSubmitButton from '@/components/UI/buttons/WwSubmitButton.vue'
import WwModal from '@/components/UI/WwModal.vue'
import { DELETE_FROM_CART_MODAL } from '@/constants/modal/names.js'
import { ListingCartFragment } from '@/gql/fragments/listing.gql'
import { GetCarts } from '@/gql/queries/carts.gql'
import { logError } from '@/utils/error-handling.js'

export default {
	components: {
		WwModal,
		CloseIcon,
		WwSubmitButton
	},
	data() {
		return {
			DELETE_FROM_CART_MODAL,
			isExpanded: false,
			isLoading: false
		}
	},
	computed: {
		...mapGetters([ 'isMobile' ]),
		...mapGetters('modal', [ 'activeModalId' ]),
		...mapGetters('cart', [ 'cart' ]),
		stagedItemToRemove() {
			return this.cart?.itemToRemove.items[0].name
		},
		listingId() {
			return this.cart?.itemToRemove?.listing_id || ''
		}
	},
	watch: {
		activeModalId(newValue) {
			if (newValue === DELETE_FROM_CART_MODAL) {
				setTimeout(() => {
					this.isExpanded = true
				}, 150)
			} else {
				this.isExpanded = false
			}
		}
	},
	methods: {
		...mapMutations('slideOver', [ 'showSlideOver' ]),
		...mapMutations('modal', [ 'closeModal' ]),
		...mapActions('cart', [ 'removeFromCart' ]),
		async handleDeleteButtonClick() {
			this.isLoading = true
			try {
				const response = await this.removeFromCart(this.cart.itemToRemove)
				const { carts } = this.$apollo.provider.defaultClient.readQuery({
					query: GetCarts
				})
				const listingId = this.listingId
				const cartIndex = carts?.findIndex(cart => parseInt(cart.listing?.id) === parseInt(listingId))
				const itemsCopy = [ ...carts[cartIndex]?.items ]
				const itemIndex = itemsCopy?.findIndex(item => parseInt(item.productId) === parseInt(this.cart.itemToRemove.items[0].product_id) && parseInt(item.size) === parseInt(this.cart.itemToRemove.items[0].size))
				itemsCopy.splice(itemIndex, 1)
				const newCart = {
					...carts[cartIndex],
					numItems: response?.data?.results?.num_items,
					tax: response?.data?.results?.tax,
					total: response?.data?.results?.total,
					subTotal: response?.data?.results?.subtotal,
					items: itemsCopy
				}
				if (!itemsCopy.length) {
					carts.splice(cartIndex, 1)
				} else {
					carts.splice(cartIndex, 1, newCart)
				}
				this.$apollo.provider.defaultClient.writeQuery({
					query: GetCarts,
					variables: {
						listingId: listingId
					},
					data: {
						carts
					}
				})
				this.$apollo.provider.defaultClient.writeFragment({
					fragment: ListingCartFragment,
					id: `Listing:${this.listingId}`,
					data: {
						__typename: 'Listing',
						id: this.listingId,
						cart: {
							__typename: 'Cart',
							...newCart
						}
					}
				})
				this.$nextTick(() => {
					this.closeModal(DELETE_FROM_CART_MODAL)
				})
			} catch (e) {
				this.closeModal(DELETE_FROM_CART_MODAL)
				logError(e)
			}
			this.isLoading = false
		}
	}
}
</script>

<style scoped>
	.fade-in-move,
	.fade-in-enter-active,
	.fade-in-leave-active {
		transition: all .5s ease;
		position: absolute;
	}
	.fade-in-enter,
	.fade-in-leave-to {
		opacity: 0;
		transform: translateY(100%);
		transition: all 0.5s ease;
	}
</style>
